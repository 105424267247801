@tailwind base;
@tailwind components;
@tailwind utilities;
#stylescroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

#stylescroll::-webkit-scrollbar-thumb {
  background-color: #afaeae;
  border-radius: 4px;
  /* border: 1px solid #555555; */
}
