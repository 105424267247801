/*app*/
#taggoai .app {
  position: relative;
  width: 100vw;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow-x: hidden;
}

#taggoai .mobile {
  position: fixed;
  height: 100vh;
  height: 100svh;
  width: 100vw;
  max-width: 100vw;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow-x: hidden;
}

#taggoai .container {
  position: absolute;
  right: 15px;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

#taggoai .message {
  transition: ease-in-out 0.3s;
  opacity: 0;
  margin: 0;
  position: absolute;
  right: 15px;
  bottom: 75px;
}

#taggoai .open {
  transition: ease-in-out 0.3s;
  opacity: 1;
}

#taggoai .chat {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #0031de;
  color: #fff;
  position: absolute;
  bottom: 15px;
  right: 15px;
  margin: 0;
  /* .chat:hover{
   background: rgb(60, 252, 188);
   } */
}

#taggoai .chat-open {
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #0031de;
  position: absolute;
  bottom: 15px;
  right: 15px;
  margin: 0;
  /* .chatmobi:hover{
   background: rgb(60, 252, 188);
   } */
}

#taggoai .container-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  position: relative;
  bottom: 0;
  right: 0;
  /*For mobile*/
}

#taggoai .container-mobile .chat-open {
  border: 1px solid #eee;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: aquamarine;
  color: white;
}

#taggoai .container-mobile .chat-open:hover {
  background: #3cfcbc;
}

#taggoai .first-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*customer*/
#taggoai .height_mess {
  height: calc(100vh -50px);
  height: calc(100svh -50px);
}

#taggoai .height_header {
  height: 50px;
}

/*chat*/

/* Chrome, Safari, and Edge */
.scrollable-style::-webkit-scrollbar {
  width: 2px;
}
.scrollable-style::-webkit-scrollbar-thumb {
  background-color: rgb(197, 186, 186);
}